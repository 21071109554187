module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.14.0_gatsby@5.14.1_babel-eslint@10.1.0_eslint@9.23.0_jiti@2.4._6893276b5f7320b205a9986672c2209a/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"WhidyWrites","short_name":"writes","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c31ee7a877b1748ad5b63d0d1c5f049c"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-google-gtag@5.14.0_gatsby@5.14.1_babel-eslint@10.1.0_eslint@9.23.0_jiti@2_0bc82f3e0cca32db75b7f0f4ab8a1deb/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-T2RFYBDB0N"],"gtagConfig":{}},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.14.1_babel-eslint@10.1.0_eslint@9.23.0_jiti@2.4.2___react-dom@18.3.1_react@18._49423178d60653a80bd668e74a2a5dab/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
