/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
const { config } = require('@fortawesome/fontawesome-svg-core')
require('@fortawesome/fontawesome-svg-core/styles.css')
require('github-markdown-css/github-markdown-light.css')
require('./src/css/index.css')
// console.log(process.env.LogRocket)
const LogRocket = require('logrocket')
config.autoAddCss = false

if (process.env.LogRocket) {
  LogRocket.init('4hsopa/blog')
}
// import React from "react"
// import Layout from "./src/components/layout"

// You can delete this file if you're not using it
// Logs when the client route changes
// export const onRouteUpdate = ({ location, prevLocation }) => {
//   console.log("new pathname", location.pathname)
//   console.log("old pathname", prevLocation ? prevLocation.pathname : null)
// }
// export const wrapPageElement = (ctx) => {
//   console.log(ctx)
//   const {props} = ctx
//   let url = props.location.href
//   let maybeOld = false
//   if (url.match('//www.whidy.net')) {
//     maybeOld = true
//     url = url.replace('//www.whidy.net', '//www.whidy.cn');
//   }
//   return (
//     <Layout>
//       <p style={{ display: maybeOld ? 'block' : 'none' }}>因域名变更，你要找的内容也许在这里：<a href={url} className="text-green-700 underline">{url}</a>，希望能对你有所帮助。</p>
//     </Layout>
//   )
// };
